<template>
    <section>
        <form @submit.prevent="onSubmit" class="form-horizontal form-element mb-20 col-12">
            <div class="row">
                <p class="text-danger" v-if="error">{{errorMessage}}</p>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <input v-model="firstName" type="text" class="form-control" placeholder="First Name">
                        <p class="error" v-if="firstNameError">{{firstNameErrorMessage}}</p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <input v-model="lastName" type="text" class="form-control" placeholder="Last Name">
                        <p class="error" v-if="lastNameError">{{lastNameErrorMessage}}</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <input v-model="email" type="text" class="form-control" placeholder="Email">
                        <p class="error" v-if="emailError">{{emailErrorMessage}}</p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <input v-model="phone" type="text" class="form-control" placeholder="Phone">
                        <p class="error" v-if="phoneError">{{phoneErrorMessage}}</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group">
                    <button v-if="isRoleAllowedDelete" class="btn btn-lg btn-primary" @click="updateAdminData">Update<span v-if="loading" class="spinner-border text-white"></span></button>
                </div>
            </div>
        </form>
    </section>
</template>

<script>
import { repository } from '../../presenter';
export default {
    name: 'AdminPersonalInformation',
    props: ['adminData'],
    mounted() {
        const sweetAlert = document.createElement('script')
        sweetAlert.setAttribute('src', '/assets/styles/vendor_components/sweetalert/sweetalert.min.js')
        document.head.appendChild(sweetAlert)
    },
    data(){
        return {
            firstName: this.adminData.first_name,
            firstNameError: false,
            firstNameErrorMessage: '',
            lastName: this.adminData.last_name,
            lastNameError: false,
            lastNameErrorMessage: '',
            email: this.adminData.email,
            emailError: false,
            emailErrorMessage: '',
            phone: this.adminData.phone,
            phoneError: false,
            phoneErrorMessage: '',

            loading: false,
            error: false,
            errorMessage:'',
        }
    },
    computed: {
        isRoleAllowedDelete(){
            return repository.adminIsLord()
        },
    },
    methods:{
        async updateAdminData () {
            if (this.loading) return
            this.resetData()
            const adminDataValidation = this.validateAdminData()
            if (!adminDataValidation.success) return
            this.loading = true
            const data = {
                userID: this.adminData.id,
                email: this.email,
                first_name: this.firstName,
                last_name: this.lastName,
                phone: this.phone,
            }
            const response = await repository.user.updateAdminData(data)
            this.loading = false
            if (response.success) {
                this.showSuccessMessage('Admin Profile Updated');
                return
            }
            this.showErrorMessage(response.data)
        },

        validateAdminData(){
            const returnValue = new Object()
            if (!this.firstName){
                this.firstNameError=true;
                this.firstNameErrorMessage='Enter first name';
                returnValue.success = false
                return returnValue
            }
            if (!this.lastName){
                this.lastNameError=true;
                this.lastNameErrorMessage='Enter last name';
                returnValue.success = false
                return returnValue
            }
            
            if (!this.email) {
                this.emailError=true;
                this.emailErrorMessage='Enter valid email';
                returnValue.success = false
                return returnValue
            }

            if (!this.phone) {
                this.phoneError=true;
                this.phoneErrorMessage='Enter valid phone number';
                returnValue.success = false
                return returnValue
            }

            

            returnValue.success = true
            return returnValue
        },

        showSuccessMessage (message) {
            swal(message, "", "success")
        },
        showErrorMessage (message) {
            this.error = true
            this.errorMessage = message
        },

        resetData() {
            this.error = false
            this.errorMessage = ''
            this.firstNameError = false
            this.lastNameError = false
            this.emailError = false
            this.phoneError = false
        },


    }
}
</script>

<style scoped>
@import url('/assets/styles/vendor_components/sweetalert/sweetalert.css');
</style>
