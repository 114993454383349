<template>
    <section>
        <form @submit.prevent="onSubmit" class="form-horizontal form-element mb-20 col-12">
            <div class="row">
                <p class="text-danger" v-if="error">{{errorMessage}}</p>
            </div>
            <div class="row">
                <div class="form-group">
                    <label>Rider Type</label>
                    <div class="demo-checkbox">
                        <input type="checkbox" id="pickup_ridertype" class="chk-col-red" value="pickup" v-model="riderType"/>
                        <label for="pickup_ridertype">Pickup</label>
                        <input type="checkbox" id="delivery_ridertype" class="chk-col-red" value="delivery" v-model="riderType"/>
                        <label for="delivery_ridertype">Delivery</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group">
                    <label>Assign Region</label>
                    <select v-model="destinationType" @change="onDestinationTypeChange" class="form-control">
                        <option value="">Select Destination Type</option>
                        <option value="within-city">Within City</option>
                        <option value="within-state">Within State</option>                                
                        <option value="within-country">Within Country</option> 
                    </select>
                    <p class="error" v-if="destinationTypeError">{{destinationTypeErrorMessage}}</p>
                </div>
                <div v-if="boundPlaceList.length" class="form-group">
                    <label>Select Region</label>
                    <p class="error" v-if="selectedBoundPlaceError">{{selectedBoundPlaceErrorMessage}}</p>
                    <div class="demo-checkbox">
                        <div v-for="boundPlace in boundPlaceList" :key="boundPlace.id">
                            <input type="checkbox" :id="boundPlace.id" class="chk-col-red" :value="boundPlace.id" v-model="selectedBoundedPlaces"/>
                            <label :for="boundPlace.id">{{boundPlace.name}}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group">
                    <button v-if="isRoleAllowedDelete" class="btn btn-lg btn-primary" @click="updateAdminData">Update<span v-if="loading" class="spinner-border text-white"></span></button>
                </div>
            </div>
        </form>
    </section>
</template>

<script>
import { repository } from '../../presenter';
import { mapGetters } from 'vuex'
export default {
    name: 'AdminPersonalInformation',
    props: ['adminData'],
    mounted() {
        const sweetAlert = document.createElement('script')
        sweetAlert.setAttribute('src', '/assets/styles/vendor_components/sweetalert/sweetalert.min.js')
        document.head.appendChild(sweetAlert)

        this.adminData.rider_assigned_nomenclatures.forEach((assignedNomenclature)=>{
            this.selectedBoundedPlaces.push(assignedNomenclature.assigned_nomenclature_id)
        })

        this.loadNomenclatures(this.adminData.rider_bound);
    },
    computed: {
        ...mapGetters('region', {
            boundPlaceList: 'retrieveNomenclatureList',
        }),
        isRoleAllowedDelete(){
            return repository.adminIsLord()
        },
    },
    data(){
        return {
            riderType: this.adminData.riderTypes,
            destinationType: this.adminData.rider_bound,
            destinationTypeError: false,
            destinationTypeErrorMessage: '',
            selectedBoundedPlaces: [],
            selectedBoundPlaceError: false,
            selectedBoundPlaceErrorMessage: '',



            loading: false,
            error: false,
            errorMessage:'',
        }
    },
    methods:{
        async loadNomenclatures(bound) {
            repository.regions.loadNomenclatureList(bound);
        },


        onDestinationTypeChange(event) {
            this.loadNomenclatures(event.target.value); // within-country, within-state, within-city
        },

        async updateAdminData () {
            if (this.loading) return
            this.resetData()
            const adminDataValidation = this.validateAdminData()
            if (!adminDataValidation.success) return
            this.loading = true
            const data = {
                userID: this.adminData.id,
                nomenclatureID: this.selectedBoundedPlaces,
                riderType: this.riderType,
            }
            const response = await repository.user.updateRiderRegion(data)
            this.loading = false
            if (response.success) {
                this.showSuccessMessage('Admin Profile Updated');
                return
            }
            this.showErrorMessage(response.data)
        },

        validateAdminData(){
            const returnValue = new Object()
            if (!this.riderType.length){
                this.error=true;
                this.errorMessage='Enter first name';
                returnValue.success = false
                return returnValue
            }
            if (!this.selectedBoundedPlaces.length){
                this.error=true;
                this.errorMessage='Enter first name';
                returnValue.success = false
                return returnValue
            }

            returnValue.success = true
            return returnValue
        },

        showSuccessMessage (message) {
            swal(message, "", "success")
        },
        showErrorMessage (message) {
            this.error = true
            this.errorMessage = message
        },

        resetData() {
            this.error = false
            this.errorMessage = ''
        },


    }
}
</script>

<style scoped>
@import url('/assets/styles/vendor_components/sweetalert/sweetalert.css');
</style>
